import React from "react"
import SEO from "../components/SEO"

import { ClientLogoList } from "../components/ClientLogoList"
import { Container, Row, Col } from "reactstrap"

import Faq from "../components/FAQ/Refferal"

import refferal_header_image from "../../static/images/2/referral/referral_header_image@2x.jpg"

import refer_by_email from "../../static/images/2/referral/refer_by_email.svg"
import chat_directly from "../../static/images/2/referral/chat_directly.svg"
import vouchers from "../../static/images/2/referral/vouchers.svg"

import money_management from "../../static/images/2/referral/money_management.svg"
import marketing_strategy from "../../static/images/2/referral/marketing_strategy.svg"
import goals_management from "../../static/images/2/referral/goals_management.svg"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"

import { RefferalEmailCopy } from "../components/RefferalEmailCopy"
class RefferalPage extends React.Component {
  render() {
    return (
      <>
        <SEO
          title="brillea - refer brillea"
          description="We'd like you to be a brillea referral partner"
        />

        <div className={"section section_header section_large"}>
          <Container>
            <Row className="mb-4">
              <Col>
                <h1 className="color_purple text-center mb-4">
                  What would you spend £250
                  <br /> of Amazon vouchers on?
                </h1>
              </Col>
            </Row>
            <Row className="pt-4">
              <Col
                className={"homeHeaderContent"}
                md={{ size: 5 }}
                sm={{ size: 8 }}
              >
                <h2 className="mb-3 mt-3 color_purple_dark">
                  Get £250 for every <br />
                  business you refer
                </h2>
                <p>
                  We value your judgement, so when you refer a business you
                  believe we can help,{" "}
                  <span className="color_purple">
                    we'll send you a £250 Amazon Gift Card
                  </span>{" "}
                  if they become a paid customer.
                </p>
                <p>
                  Even better, you'll be genuinely helping a deserving business
                  with their marketing and growth challenge.{" "}
                  <span className="color_purple">
                    We'll even give them £250 off their first month of brillea.
                  </span>{" "}
                  Easy, peezy, right?
                </p>
              </Col>
              <Col
                className="homeHeaderImage_wrapper"
                md={{ size: 7 }}
                sm={{ size: 4 }}
              >
                <img
                  src={refferal_header_image}
                  alt="brillea agency alternative"
                  style={{maxWidth:"100%"}}
                />
              </Col>
            </Row>
          </Container>
        </div>

        <div className={"section bk-grey section_large text-center"}>
          <Container>
            <Row>
              <Col className={"text-center mb-4"}>
                <h2 className="h1 color_purple">Why we're worth the referral</h2>
                <p>
                  brillea takes the stress out of digital marketing with one
                  easy, effective and affordable solution.
                </p>
              </Col>
            </Row>

            <Row>
              <Col md={{ size: 4 }} sm={{ size: 12 }}>
                <img
                  className="img_h75 mb-3"
                  src={marketing_strategy}
                  alt={"Refer brillea by email"}
                />
                <h3>Proven growth process</h3>
                <p>
                  Used by successful startups
                  <br /> &amp; global brands. More importantly, <br />
                  it just works
                </p>
              </Col>
              <Col md={{ size: 4 }} sm={{ size: 12 }}>
                <img
                  className="img_h75 mb-3"
                  src={money_management}
                  alt={"brillea call direct"}
                />
                <h3>Cost effective</h3>
                <p>
                  We're cheaper than comparable agencies, freelancers <br />
                  or permanent hires
                </p>
              </Col>
              <Col md={{ size: 4 }} sm={{ size: 12 }}>
                <img
                  className="img_h75 mb-3"
                  src={goals_management}
                  alt={"brillea call direct"}
                />
                <h3>Designed to win</h3>
                <p>
                  We're on a mission to help deserving startups and small businesses grow through their marketing
                </p>
              </Col>
            </Row>
          </Container>
        </div>

        <div className={"section section_large text-center"}>
          <Container>
            <Row>
              <Col className={"text-center mb-4"}>
                <h2 className="h1 color_purple">
                  Its super simple to refer someone
                </h2>
                <p>
                  But hurry! You have to get your referral in before <strong>31st October (spooky!)</strong>
                </p>
              </Col>
            </Row>

            <Row>
              <Col md={{ size: 4 }} sm={{ size: 12 }}>
                <img
                  className="img_h75 mb-3"
                  src={refer_by_email}
                  alt={"Refer brillea by email"}
                />
                <h3>Drop them an email</h3>
                <p>
                  Simply drop your contact an introductory email and CC{" "}
                  <a href={"mailto:liam@brillea.com"}>liam@brillea.com</a> before 31.10.2020
                </p>
              </Col>
              <Col md={{ size: 4 }} sm={{ size: 12 }}>
                <img
                  className="img_h75 mb-3"
                  src={chat_directly}
                  alt={"brillea call direct"}
                />
                <h3>We'll chat directly</h3>
                <p>
                  We'll find out what their marketing goals are and explain how
                  we can help
                </p>
              </Col>
              <Col md={{ size: 4 }} sm={{ size: 12 }}>
                <img
                  className="img_h75 mb-3"
                  src={vouchers}
                  alt={"brillea call direct"}
                />
                <h3>Get your vouchers</h3>
                <p>
                  We'll email your <strong className="color_purple">£250 Amazon Gift Card</strong> when they’ve become a brillea customer
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <RefferalEmailCopy />
              </Col>
            </Row>
          </Container>
        </div>

        <div className={"section section_large bk-grey "}>
          <Container>
            <Row>
              <Col
                className={"homeHeaderContent"}
                md={{ size: 5, offset: 1 }}
                sm={{ size: 12 }}
              >
                <h2 className="mb-3 h1 color_purple">Who we’re looking for</h2>
                <p>
                  We understand the challenges startups and small businesses
                  face with their marketing and getting early customers, so we
                  created brillea to make this easier.
                </p>
                <p>Help us help those who need marketing help!</p>
              </Col>
              <Col
                className="homeHeaderImage_wrapper mt-4"
                md={{ size: 5, offset: 1 }}
                sm={{ size: 12 }}
              >
                <ul className="tick_list ">
                  <li>
                    <FontAwesomeIcon icon={faCheck} />{" "}
                    <p>
                      Businesses who need sales, sign ups or app downloads to
                      grow
                    </p>
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} />{" "}
                    <p>B2C startups and smaller businesses</p>
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} />{" "}
                    <p>
                      Businesses who can’t afford an agency but need marketing
                      help
                    </p>
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} />{" "}
                    <p>
                      Businesses where Facebook, Instagram and / or Google Search seem a natural fit.
                    </p>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>

        {/* <div className={"section section_large bk-purple text-left"}>
          <Container>
              <Row>
                <Col md={{ size:9, offset:2}}>
                  <blockquote className="h1 color_white">"I've been able to achieve my business goals through brillea, without taking time out from running my business."</blockquote>
                  <p className="color_white"><small>MAUDE, BRILLEA CUSTOMER</small></p>
                </Col>
              </Row>
          </Container>
        </div> */}

        <div className={"section section_medium bk-purple-dark text-center"}>
          <Container>
            <Row>
              <Col>
                <ClientLogoList size={{lg:"4", sm:"2"}}/>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="grey_bk">
          <Faq title={"Frequently asked questions"} link={"/faqs/"} />
        </div>

        <footer className="section section_medium">
        <Container className={"text-center"}>
            <Row>
                <Col md="12" >
                <p className="text-center">
<small>
<a className="underline" href={'/legal/referral-terms-and-conditions'} target="_blank" rel="noreferrer">brillea Referral T&amp;C's Apply</a> <br/>also see *Amazon’s restrictions at <a href="https://www.amazon.co.uk/gc-legal" target="_blank" rel="noreferrer">www.amazon.co.uk/gc-legal</a>
</small>
</p>

<p className="text-center">
<small>
&copy; {new Date().getFullYear()}, brillea.
</small>
</p>
                </Col>
            </Row>
        </Container>
        </footer>

      </>
    )
  }
}
export default RefferalPage
