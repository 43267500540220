import React from "react"
import { Button, Input, Modal, ModalHeader, ModalBody } from "reactstrap"
import { toast, ToastContainer } from "react-toastify"
import { CopyToClipboard } from "react-copy-to-clipboard"

export const RefferalEmailCopy = () => {
  const [value, setValue] = React.useState(
    "Hi [Name], \n\nI just want to quickly introduce you to Liam. He's the founder over at brillea.com - what seems like the perfect marketing solution for ambitious startups who are finding agencies too expensive and freelancers too inconsistent. They get the challenges startups face and genuinely want to help them win, so naturally I wanted to introduce you two. \n\nThere’s no contract or minimum term, which makes it easy to trial and see whether it could be a good fit without much commitment. Anyway, I’ll let [Liam] take it from here. \n\nMany thanks \n[Name]"
  )
  const [modal, setModal] = React.useState(false)

  const toggle = () => setModal(!modal)

  const onChange = ({ target: { value } }) => {
    setValue({ value })
  }

  const onClick = () => {
    // console.log(`Clicked on "${innerHTML}"!`); // eslint-disable-line
    toast["success"](<p>email text copied</p>)
  }

  return (
    <>
      <ToastContainer></ToastContainer>
      <p className="text-center mt-2">
        <strong>Not sure what to write?</strong>
      </p>
      <Button className="mb-0" color="secondary" onClick={toggle}>
        Use our handy email template
      </Button>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Referral email template</ModalHeader>
        <ModalBody>
          <Input
            type="textarea"
            onChange={onChange}
            value={value}
            style={{ minHeight: "400px" }}
          />
          <CopyToClipboard text={value}>
            <Button onClick={onClick} block>
              Copy email text to clipboard
            </Button>
          </CopyToClipboard>

          <p className="text-center mt-2">
            <small>
              Feel free to twaek the copy to add your personal touch!
            </small>
          </p>
        </ModalBody>
      </Modal>
    </>
  )
}
